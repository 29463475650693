const translationsAr = {
    "status": "success",
    "data": {
        "lang": "ar_AR",
        "app": "hotel",
        "translation": {
            "crud": {
                "updatedSuccessfully": "تم التحديث بنجاح",
                "validationFailed": "فشل التحقق",
                "fieldRequired": "حقل إلزامي",
                "operationError": "حدث خطأ",
                "emptyList": "لا توجد بيانات",
                "failToLoad": "خطأ أثناء التحميل",
                "modelNotFound": "غير موجود"
            },
            "updateCard": {
                "payment_failed_please_update_card": "فشل الدفع - يرجى تحديث البطاقة",
                "open_the_payment_form": "افتح نموذج الدفع"
            },
            "upgradePlan": {
                "general": {
                    "upgrade_plan": "ترقية الخطة",
                    "upgrade_plan_to_regular": "الترقية إلى الخطة العادية",
                    "upgrade_plan_to_pro": "الترقية إلى الخطة المحترفة"
                },
                "regular": {
                    "title": "قم بالترقية الآن واحصل على الوصول إلى منتجات Agent 360!",
                    "benefits_title_text": "أنت حاليا متصل بحزمة Agent 360 Light، إذا قمت بالترقية يمكنك الحصول",
                    "text_1": "منتجات جملة Sky 360",
                    "text_2": "فنادق لجميع الوجهات حول العالم",
                    "text_3": "رحلات منتظمة ورحلات منخفضة التكلفة",
                    "text_4": "معالم جذب حول العالم",
                    "button_text": "ترقية الآن إلى Agent 360!"
                },
                "pro": {
                    "title": "قم بالترقية الآن واحصل على موقع ويب لعملك مع تصميم كامل!",
                    "benefits_title_text": "في حزمة SKY 360 Pro يمكنك الاستمتاع",
                    "text_1": "موقع ويب بنطاق فريد الخاص بك",
                    "text_2": "العلامة التجارية للأعمال - الشعار وألوان الموقع",
                    "text_3": "ترويج الحزم والرحلات على الموقع حسب الوجهات",
                    "text_4": "جميع الحجوزات التي تتم ستكون تابعة لوكالتك!",
                    "button_text": "ترقية الآن إلى Pro!"
                }
            },
            "contactUs": {
                "requestForQuotation": "طلب عرض أسعار",
                "problemWithExistingOrder": "مشكلة في الطلب الحالي",
                "customerService": "خدمة العملاء",
                "other": "أخرى",
                "messageReceivedSuccessfully": "تم استلام الرسالة بنجاح",
                "email": "البريد الإلكتروني",
                "phone": "الهاتف",
                "fullName": "الاسم الكامل",
                "writeUsMessage": "اكتب لنا رسالة",
                "send": "إرسال",
                "phoneRequired": "يرجى إدخال رقم الهاتف",
                "emailRequired": "يرجى إدخال عنوان البريد الإلكتروني",
                "fullNameRequired": "يرجى إدخال الاسم الكامل",
                "messageRequired": "يرجى إدخال رسالة",
                "aboutUs": "معلومات عنا"
            },
            "cart": {
                "addToCart": "أضف إلى السلة"
            },
            "customerSupport": {
                "customerService": "خدمة العملاء",
                "sendMessageAndGetNotified": "أرسل لنا رسالة وسيرد عليك المزاد قريباً",
                "callUs": "اتصل بنا: "
            },
            "roomInfo": {
                "info": "وصف الغرفة",
                "viewAllThePhotos": "عرض جميع الصور",
                "roomSize": "مساحة الغرفة (متر مربع)",
                "peopleInTheRoom": "الأشخاص في الغرفة",
                "doubleBed": "سرير مزدوج"
            },
            "conceptsList": {
                "conceptsListTitle": "عنوان قائمة المفاهيم",
                "includesActivities": "يتضمن جذب الهدايا",
                "includesActivity": "يتضمن جذب هدية",
                "includeInPackage": "يتضمن في الحزمة",
                "transfer": "النقل",
                "includesTransfer": "يتضمن النقل",
                "transferContent": "نقل HOS بسعر الحزمة",
                "baseBoard": "لوحة الأساس",
                "autoApprove": "الموافقة التلقائية",
                "airways": "الخطوط الجوية",
                "flightFrom": "رحلة من",
                "flightBack": "رحلة العودة",
                "nights": "ليال",
                "toOrder": "للطلب",
                "noPackagesFound": "لم يتم العثور على حزم",
                "moreDeals": "المزيد من العروض",
                "pricePerPerson": "السعر لكل شخص"
            },
            "viewFlight": {
                "show_tickets_with_different_fare_combinations": "عرض التذاكر بتركيبات أسعار مختلفة",
                "ticketFare": "السعر",
                "ticketInfo": "معلومات التذكرة",
                "termsAndConditions": "الشروط والأحكام",
                "ifTicketIncludeSeat": "إذا كانت التذكرة تشمل مقعدًا - يجب على العميل اختياره من موقع الخطوط الجوية",
                "inCaseOfChange": "في حالة التغيير - يجب على العميل دفع مبلغ إضافي إذا كانت التذكرة المحدثة أعلى، وهناك أيضًا رسوم خدمة إضافية وفقًا لشروط وخدمات الموقع",
                "inCaseOfCancellation": "في حالة الإلغاء - ستكون هناك رسوم خدمة إضافية وفقًا لشروط وخدمات الموقع",
                "error4305Title": "عذرًا، الرحلة محجوزة بالكامل",
                "error4305Content": "نعتذر، ولكن يبدو أن المقاعد لهذا العرض قد نفدت، ولا يمكننا المتابعة مع الشراء. سيتم توجيهك إلى الصفحة الرئيسية.",
                "TC": "الشروط والأحكام",
                "flights": "الرحلات",
                "title": "الرحلة المختارة، تحقق من التفاصيل وتابع إلى الدفع",
                "addBaggage": "إضافة أمتعة",
                "closeAddBaggage": "إخفاء الخيارات",
                "addTrolly": "إضافة عربة",
                "somethingWetWrong": "حدث خطأ ما",
                "suitcasePrice": "سعر الحقيبة",
                "trollyPrice": "سعر العربة",
                "totalPerPassenger": "المجموع لكل راكب",
                "updatePassengers": "تحديث عدد الركاب",
                "search": "بحث",
                "seating": "المقاعد",
                "cancelation": "إلغاء التذكرة",
                "meal": "وجبات",
                "trolly": "عربة",
                "suitcase": "حقيبة",
                "changeFlight": "تغيير التذكرة",
                "extension": "تمديد",
                "totalToBePaid": "المجموع الواجب دفعه",
                "choose": "اختيار",
                "checkAmenities": "تحقق من وسائل الراحة",
                "description": "الوصف",
                "amenityType": "نوع وسائل الراحة",
                "flightFromAmenities": "وسائل راحة لرحلة الذهاب",
                "flightBackAmenities": "وسائل راحة لرحلة العودة",
                "BAGGAGE": "الأمتعة",
                "2 CHECKED BAGS UP TO 32KG EACH": "حقيبتان مسجلتان تصل كل منهما إلى 32 كجم",
                "2 CABIN BAGS UP TO 8KG": "حقيبتان في الكابينة تصل كل منهما إلى 8 كجم",
                "BRANDED_FARES": "أسعار العلامات التجارية",
                "STANDARD SEAT RESERVATION": "حجز مقعد قياسي",
                "MILEAGE_ACCRUAL": "تراكم الأميال",
                "MEAL": "وجبة",
                "SNACK": "وجبة خفيفة",
                "COMPLIMENTARY FOOD AND BEV": "طعام ومشروبات مجانية",
                "ENTERTAINMENT": "ترفيه",
                "MAGAZINES / NEWSPAPER": "مجلات / صحف",
                "LOUNGE": "صالة",
                "LOUNGE ACCESS": "دخول الصالة",
                "TRAVEL_SERVICES": "خدمات السفر",
                "PRIORITY BAGGAGE": "أمتعة الأولوية",
                "PRIORITY CHECK IN": "تسجيل دخول الأولوية",
                "PRIORITY SECURITY": "أمان الأولوية",
                "PRIORITY BOARDING": "صعود الأولوية",
                "IN FLIGHT ENTERTAINMENT": "ترفيه أثناء الرحلة",
                "PRE_RESERVED_SEAT": "مقعد محجوز مسبقًا",
                "PRIVACY SEAT BUSINESS CLASS": "مقعد خصوصية درجة رجال الأعمال",
                "CHANGE BEFORE DEPARTURE": "تغيير قبل المغادرة",
                "CHANGE AFTER DEPARTURE": "تغيير بعد المغادرة",
                "REFUND BEFORE DEPARTURE": "استرداد قبل المغادرة",
                "REFUND AFTER DEPARTURE": "استرداد بعد المغادرة",
                "allFares": "جميع الأسعار",
                "fareChangedSuccessfully": "تم تغيير السعر بنجاح",
                "openFares": "فتح الأسعار",
                "selectedFare": "السعر المختار",
                "fullInfo": "معلومات كاملة",
                "MILEAGE ACCRUAL": "تراكم الأميال"
            },
            "room": {
                "roomSize": "حجم الغرفة",
                "bedType": "نوع السرير",
                "breakfastIncluded": "الإفطار مشمول",
                "noAvailabilityOnSelectedDates": "لا توجد غرف متوفرة في التواريخ المختارة"
            },
            "promotion": {
                "modelNotFound": "الصفحة غير موجودة",
                "failToLoad": "فشل في تحميل الصفحة!"
            },
            "searchDatePicker": {
                "searchHotelsByNameTitle": "البحث عن فندق بالاسم للوجهة المحددة",
                "choose_packages_according_to": "اختر الحزم حسب",
                "tech_stops": "توقفات فنية",
                "freePlanFlightSearchFooterText": "الخطة المجانية تسمح بالبحث عن رحلات الطيران العارض فقط! إذا كنت تريد رحلات GDS",
                "clickHere": "اضغط هنا",
                "approveSelection": "اعتماد الاختيار",
                "attractions": "معالم",
                "attractions_type": "أنواع المعالم",
                "room": "غرفة",
                "addRoom": "إضافة غرفة",
                "removeRoom": "إزالة غرفة",
                "indirectFlight": "رحلة غير مباشرة",
                "directFlightLabel": "رحلة مباشرة",
                "city": "مدينة",
                "hotel": "فندق",
                "searchForDestination": "ابحث عن وجهات",
                "searchByDestinationAndHotel": "البحث حسب الوجهات والفنادق",
                "date": "التاريخ",
                "noResultsForThisDate": "لا توجد حزم لهذا التاريخ",
                "searchDestination": "بحث عن وجهة",
                "pleaseSelectDestination": "يرجى اختيار وجهة",
                "airlineInformation": "معلومات الخطوط الجوية",
                "airlineCode": "الرمز",
                "title": "احجز إجازة أحلامك",
                "hotelOnly": "ابحث عن إجازة حسب التواريخ",
                "hotelWithFlight": "ابحث عن إجازة تشمل الرحلة",
                "oneWay": "ذهاب فقط",
                "roundTrip": "ذهاب وعودة",
                "selectDate": "اختيار التواريخ",
                "startDate": "تاريخ البدء",
                "endDate": "تاريخ الانتهاء",
                "flights": "بحث عن رحلات",
                "packages": "حزم العطلات",
                "hotels": "فنادق خارج البلاد",
                "israelPackage": "إجازة في إسرائيل",
                "specialPackage": "حزم خاصة",
                "passengers": "الركاب",
                "adults": "البالغون",
                "adult": "بالغ",
                "child": "طفل",
                "children": "أطفال",
                "infant": "رضيع",
                "infants": "رضع",
                "infantsWarningMessage": "يجب أن يكون الرضيع أقل من سنتين في رحلة الذهاب والعودة",
                "select": "اختر",
                "noResults": "لا نتائج",
                "checkout": "احجز الآن",
                "regularPrice": "السعر العادي",
                "from": "من",
                "purchase": "شراء",
                "selectNewFlight": "اختر رحلة جديدة",
                "search": "بحث",
                "rooms": "غرف",
                "composition": "تكوين",
                "chooseComposition": "اختر التكوين",
                "numberOfGuests": "عدد الضيوف",
                "checkOut": "تسجيل المغادرة",
                "checkIn": "تسجيل الوصول",
                "whatVacation": "أي نوع من الإجازات",
                "youWant": "تريد",
                "vacationFriendsWith": "إجازة مع الأصدقاء",
                "familyVacation": "إجازة عائلية",
                "romanticVacation": "إجازة رومانسية",
                "notAvailable": "غير متاح",
                "roomAvailableDuringTheWeek": "توافر الغرفة خلال الأسبوع",
                "followingDates": "التواريخ التالية",
                "vacation": "إجازة",
                "abroad": "في الخارج",
                "inIsrael": "في البلاد",
                "destination": "جميع الوجهات",
                "chooseDestination": "اختر الوجهات",
                "findDreamVacation": "ابحث عن إجازتك المثالية بنقرة واحدة",
                "searchResTitle": "اختر الحزمة المناسبة لك",
                "directFlight": "رحلة مباشرة",
                "stops": "توقفات",
                "checkHotel": "تحقق من الفندق",
                "noRating": "بدون تقييم",
                "toOrder": "للطلب",
                "selectAgain": "اختر مرة أخرى",
                "selectFlight": "اختر رحلة",
                "reselectFlight": "اختر الرحلة مرة أخرى",
                "flightSelectedSuccessfully": "تم اختيار الرحلة بنجاح",
                "dynamicFlight": "رحلة ديناميكية",
                "bundle": "رحلة مجمعة",
                "freeCancelation": "إلغاء مجاني",
                "partially_operated_by_airlines": "تشغيل جزئي بواسطة شركات الطيران",
                "connect_in_airport": "التوصيل في المطار",
                "arrives": "يصل",
                "journey_duration": "مدة الرحلة",
                "short_wait": "انتظار قصير",
                "long_wait": "انتظار طويل",
                "flight_number": "رقم الرحلة",
                "stop_1": "توقف واحد",
                "activeFlight": "رحلة نشطة",
                "flightSearch": "بحث عن رحلة",
                "nights": "ليال",
                "until": "حتى التاريخ",
                "untilYouSearchForFlightDate": "حتى تاريخ بحث الرحلة",
                "destinationFrom": "المغادرة",
                "packageSearch": "بحث عن حزمة",
                "destinationTo": "الوصول",
                "selectDestination": "اختيار الوجهة",
                "trollyIsIncluded": "العربة مشمولة",
                "trollyIsNotIncluded": "العربة غير مشمولة",
                "suitcaseIsIncluded": "الحقيبة مشمولة",
                "suitcaseIsNotIncluded": "الحقيبة غير مشمولة",
                "operated_by_airlines": "تشغيل بواسطة"
            },
            "specialDeal": {
                "closeTheDeal": "إغلاق الصفقة ›",
                "endsIn": "ينتهي في",
                "specialDealForTheWeekend": "صفقة خاصة لعطلة نهاية الأسبوع",
                "from": "من تاريخ",
                "until": "حتى تاريخ"
            },
            "footer": {
                "backToTop": "العودة إلى الأعلى",
                "allRightsReserved": "© جميع الحقوق محفوظة. يمنع نسخ المحتوى من الموقع بدون إذن.",
                "developBy": "تطوير بواسطة SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "الشراء على الموقع آمن.",
                "talkToUs": "كيف تتحدث إلينا؟",
                "address": "العنوان",
                "phoneAndWhatsapp": "الهاتف أو WhatsApp",
                "phone": "الهاتف",
                "whatsapp": "WhatsApp"
            },
            "attractions": {
                "showingMoreResults": "عرض المزيد من النتائج",
                "noMoreResults": "لا يوجد المزيد من النتائج",
                "NoResultsMatchedTheFilterValues": "لا توجد نتائج تطابق قيم الفلتر",
                "inclusions": "المشمولات",
                "exclusions": "الاستثناءات",
                "overviewOfTheTrip": "نظرة عامة على الرحلة",
                "immediateConfirmation": "تأكيد فوري",
                "pricePerPerson": "السعر لكل شخص",
                "totalForXXXPeople": "الإجمالي لـ XXX أشخاص",
                "toOrder": "للطلب",
                "freeCancelation": "إلغاء مجاني",
                "showAll": "عرض التفاصيل",
                "hideAll": "إخفاء التفاصيل",
                "days": "أيام",
                "rating": "التقييم",
                "supplier": "المورد",
                "location": "الموقع",
                "pricePerPersonFrom": "السعر لكل شخص من",
                "selectDatesAndTravelers": "اختر التواريخ والمسافرين",
                "bookNowWithFreeCancellation": "احجز الآن مع إمكانية الإلغاء المجاني",
                "updateSearch": "تحديث البحث",
                "activities-health-wellness-spas": "المنتجعات",
                "activities-action-entertainment-shows-events": "عروض وفعاليات",
                "activities-nature-outdoors-zoos-aquariums": "حدائق الحيوان والأحياء المائية",
                "activities-food-drink": "الطعام والشراب",
                "activities-action-entertainment-shopping": "التسوق",
                "activities-nature-outdoors-tickets-passes": "تذاكر وبطاقات الدخول",
                "activities-action-entertainment-rentals-rides": "التأجير والرحلات",
                "activities-transportation": "النقل",
                "activities-culture-shows-events": "عروض ثقافية وفعاليات",
                "activities-nature-outdoors": "الطبيعة والهواء الطلق",
                "activities-tours-by-theme-shopping-tours": "جولات تسوق",
                "activities-workshops-classes-sport-lessons": "دروس رياضية",
                "activities-action-entertainment-tickets-passes": "تذاكر وبطاقات",
                "activities-workshops-classes-music-dance": "موسيقى ورقص",
                "activities-food-drink-workshops-classes": "ورش عمل وفصول",
                "activities-health-wellness-relaxation": "استرخاء",
                "activities-tours-by-theme-food-drink-tours": "جولات الطعام والشراب",
                "activities-workshops-classes": "ورش عمل وفصول",
                "activities-health-wellness-beauty-treatments": "علاجات التجميل",
                "activities-action-entertainment-water": "ماء",
                "activities-workshops-classes-arts-crafts": "فنون وحرف",
                "activities-tours-by-theme": "جولات حسب النوع",
                "activities-action-entertainment-land": "بر",
                "activities-tours-by-theme-sport-tours": "جولات رياضية",
                "activities-culture": "ثقافة",
                "activities-tours-by-theme-walking-tours": "جولات سيرًا على الأقدام",
                "activities-health-wellness-alternative-medicine": "الطب البديل",
                "activities-tours-by-theme-art-culture-tours": "جولات الفن والثقافة",
                "activities-workshops-classes-cultural-immersion": "الغمر الثقافي",
                "activities-health-wellness": "الصحة والعافية",
                "activities-nature-outdoors-air-activities": "أنشطة جوية",
                "activities-tours-by-theme-luxury-tours": "جولات فاخرة",
                "activities-tours-by-theme-historical-heritage-tours": "جولات التراث التاريخي",
                "activities-culture-art-culture-tours": "جولات الفن والثقافة",
                "activities-action-entertainment-air": "أنشطة جوية",
                "activities-tours-by-theme-nature-wildlife-tours": "جولات الطبيعة والحياة البرية",
                "activities-tours-by-theme-discovery-tours": "جولات الاكتشاف",
                "activities-nature-outdoors-wildlife-nature-observation": "مراقبة الطبيعة والحياة البرية",
                "activities-action-entertainment": "أكشن وترفيه",
                "activities-tours-by-theme-museum-exhibition-tours": "جولات المتاحف والمعارض",
                "activities-workshops-classes-food-drink": "ورش الطعام والشراب",
                "activities-tours-by-theme-virtual-tours": "جولات افتراضية",
                "activities-tours-by-theme-special-tours": "جولات خاصة",
                "activities-nature-outdoors-land-activities": "أنشطة برية",
                "activities-transportation-rentals-rides": "التأجير والرحلات",
                "activities-nature-outdoors-water-activities": "أنشطة مائية",
                "activities-food-drink-restaurants-bars": "مطاعم وبارات",
                "activities-food-drink-food-drink-tours": "جولات الطعام والشراب",
                "activities-no-category": "بدون تصنيف",
                "activities-health-wellness-retreats": "المعتكفات",
                "activities-action-entertainment-sport-lessons": "دروس رياضية",
                "activities-tours-by-theme-self-guided-tours": "جولات ذاتية التوجيه",
                "activities-action-entertainment-multi-adventure-games": "ألعاب المغامرات المتعددة",
                "activities-food-drink-tickets-passes": "تذاكر وبطاقات",
                "activities-tours-by-theme-night-tours": "جولات ليلية",
                "activities-culture-tickets-passes": "تذاكر وبطاقات",
                "activities-transportation-transfers": "التحويلات",
                "hours": "ساعات"
            },
            "packages": {
                "availableSeats": "مقاعد متوفرة",
                "from": "من تاريخ",
                "until": "حتى تاريخ",
                "breakfastIncluded": "يشمل الإفطار",
                "includesMassageAndSpa": "يشمل مساج وسبا!",
                "lastRoom": "الغرفة الأخيرة!",
                "includesTransfers": "يشمل التحويلات",
                "includesActivities": "يشمل الأنشطة",
                "nights": "ليال",
                "withoutBaggage": "بدون أمتعة",
                "directFlight": "رحلة مباشرة",
                "oneStop": "توقف واحد",
                "numOfStops": "عدد التوقفات",
                "flightDetails": "تفاصيل الرحلة",
                "flightFrom": "من -> إلى",
                "flightBack": "من -> إلى",
                "hours": "ساعات",
                "flightNumber": "رقم الرحلة",
                "airLine": "الخطوط الجوية",
                "suitcaseWeight": "وزن الحقيبة المشمول كجم",
                "trollyWeight": "وزن العربة المشمول كجم",
                "includedActivitiesTitle": "الجذب والجولات المشمولة في الحزمة",
                "checkAllPictures": "عرض جميع الصور",
                "affiliateWith": "بالتعاون مع",
                "addButtonDisabledTooltip": "الطلب محدود بكمية الغرف - لا يمكن إضافة غرفة أخرى. لتغيير غرفة، قم بإزالة غرفة من السلة",
                "holidaySchedule": "جدول العطلة",
                "passengers": "الركاب",
                "isRoundTrip": "سعر الأمتعة للرحلة الذهاب والعودة *",
                "includesFlights": "يشمل الرحلات",
                "returnFlight": "رحلة العودة",
                "outboundFlight": "رحلة الذهاب",
                "trollyWeight2": "وزن العربة/الحقيبة كجم",
                "suitcaseWeight2": "وزن الحقيبة كجم",
                "included": "مشمول",
                "notIncluded": "غير مشمول",
                "perPax": "لكل راكب",
                "addSuitcaseLater": "يمكنك إضافته لاحقًا",
                "clickForDetails": "انقر هنا لتفاصيل الجذب",
                "includedInPackage": "مشمول في الحزمة!"
            },
            "roomPricing": {
                "for": "لـ",
                "addPassenger": "إضافة راكب",
                "pricePerPerson": "السعر لكل شخص",
                "pricePerRoom": "السعر لكل غرفة",
                "priceForAllRooms": "السعر لجميع الغرف",
                "payOnSpot": "الدفع عند الوصول",
                "resort_fee": "رسوم المنتجع",
                "occupancy_tax": "ضريبة الإشغال",
                "totalPrice": "السعر الإجمالي",
                "pricePerInfant": "السعر لكل رضيع",
                "composition": "التكوين",
                "adultsInTheRoom": "البالغين",
                "children": "الأطفال",
                "adults": "البالغين",
                "single": "بالغ واحد",
                "child": "طفل",
                "selectInfantCount": "اختر من القائمة",
                "infant": "رضيع",
                "1infant": "رضيع واحد",
                "2infants": "رضيعان",
                "infants": "رضع",
                "noInfants": "بدون رضع",
                "all_include": "كل شيء مشمول",
                "breakfast": "إفطار",
                "activities": "اختر جولة",
                "half_board": "نصف إقامة",
                "half_board_kosher": "نصف إقامة كوشر",
                "full_board": "إقامة كاملة",
                "sleep_only": "غرفة فقط",
                "breakfast_kidush": "وجبة الصباح والمساء يوم الجمعة",
                "addToCard": "أضف إلى الطلب",
                "inPackageDates": "في تواريخ الحزمة",
                "chooseRoom": "اختر تكوين الغرفة",
                "nights": "ليالي",
                "boardType": "نوع الإقامة",
                "filters": "فلاتر",
                "noAvailableRooms": "لا توجد غرف متاحة للتواريخ المحددة",
                "noAvailableRoomsForDates": "الغرفة غير متوفرة للتواريخ المحددة",
                "addInfant": "إضافة رضيع",
                "roomFacilities": "مرافق الغرفة",
                "show_images": "عرض الصور",
                "autoApprove": "الموافقة التلقائية",
                "includedInPrice": "مشمول في السعر",
                "until": "حتى",
                "no": "لا",
                "vat": "ضريبة القيمة المضافة",
                "is": "هو",
                "not": "ليس",
                "city_tax": "ضريبة المدينة",
                "cancellation_policy": "سياسة الإلغاء",
                "your_local_time": "*الوقت المحلي لك",
                "additional_price_info": "معلومات سعر إضافية",
                "policy": {
                    "freeCancallationUntil": "إلغاء مجاني بهذا السعر حتى",
                    "freeCancallationUntil2": "إلغاء مجاني حتى",
                    "cancallationBeCharged": "سيتم الدفع",
                    "ifYouCancelBetween": "إذا قمت بالإلغاء من",
                    "ifYouCancelModifiedBetween": "إذا قمت بالإلغاء أو التعديل بعد",
                    "cancelFullPrice": "سيتم دفع السعر الكامل للحجز",
                    "till": "حتى",
                    "rateNonRefundable": "السعر غير قابل للاسترداد عند الإلغاء"
                }
            },
            "boards": {
                "RO": "غرفة فقط",
                "BB": "إفطار وسرير",
                "HB": "نصف إقامة",
                "FB": "إقامة كاملة",
                "AI": "كل شيء مشمول",
                "UI": "كل شيء مشمول ديلوكس",
                "BF": "إفطار بوفيه",
                "KB": "بوفيه إفطار بارد",
                "CB": "إفطار قاري",
                "AB": "إفطار أمريكي",
                "DR": "عشاء وغرفة",
                "EB": "إفطار إنجليزي",
                "FT": "FB+علاجات",
                "F+": "إقامة كاملة بلس",
                "GA": "عشاء احتفالي",
                "H6": "HB+6 علاجات فقط",
                "HT": "HB+علاجات",
                "IB": "إفطار إسرائيلي",
                "KO": "كوشر",
                "KS": "إفطار كوشر",
                "MI": "كل شيء مشمول ماكس",
                "RB": "غرفة وإفطار",
                "NN": "انظر الجدول",
                "SI": "كل شيء مشمول ناعم",
                "UL": "كل شيء مشمول ألترا",
                "half_board": "نصف إقامة",
                "half_board_kosher": "نصف إقامة كوشر",
                "full_board": "إقامة كاملة",
                "sleep_only": "غرفة فقط",
                "breakfast_kidush": "وجبة الصباح والمساء يوم الجمعة",
                "breakfast": "BB",
                "breakfast-for-2": "BB لشخصين",
                "nomeal": "غرفة فقط"
            },
            "activitiesTranslations": {
                "addToOrder": "إضافة إلى الطلب",
                "passenger": "إلى الراكب",
                "showMore": "عرض المزيد",
                "addAttractionsAndTrips": "إضافة جذب ورحلات",
                "doYouWantToAddAttractions": "هل تريد إضافة جذب لإجازتك؟",
                "itsTime": "حان الوقت!",
                "pricePerPerson": "السعر لكل شخص"
            },
            "modal": {
                "viewAllImages": "عرض جميع الصور",
                "galleryTitle": "معرض الصور",
                "cancelationTerms": "شروط الإلغاء -",
                "nameOfTheHotel": "تفاصيل الفندق",
                "hotelInfo": "حول الفندق",
                "hotelWebsite": "رابط موقع الفندق",
                "showMore": "عرض المزيد",
                "showLess": "عرض أقل"
            },
            "manageBaggage": {
                "manageBaggage": "إدارة الأمتعة",
                "and": "و",
                "save": "حفظ",
                "purchaseIsNotSupported": "الشراء غير مدعوم",
                "baggageIsForBothFlights": "يمكنك إضافة أمتعة لرحلة ذهاب وعودة",
                "suitcase": "حقيبة",
                "trolly": "عربة",
                "guest": "ضيف"
            },
            "shoppingCartTranslations": {
                "productIsApproved": "تمت الموافقة على هذا المنتج",
                "productIsNotApproved": "لم تتم الموافقة على هذا المنتج",
                "waitingToApprove": "هذا المنتج قيد المعالجة",
                "productIsCancelled": "تم إلغاء هذا المنتج",
                "orderIsApproved": "تمت الموافقة على هذا الطلب!",
                "orderIsNotApproved": "لم تتم الموافقة على هذا الطلب!",
                "orderBeingProcessed": "هذا الطلب قيد المعالجة",
                "orderIsCancelled": "تم إلغاء هذا الطلب!",

                "attractionPriceDescription": "سعر المعلم يعتمد على التاريخ، اختيار الخيار وعدد التذاكر",
                "selectedDate": "التاريخ المحدد",
                "selectADate": "اختر تاريخاً",
                "availableDatesBetween": "التواريخ المتاحة بين",
                "Options": "الخيارات",
                "Text": "نص",
                "Ticket type": "نوع التذكرة",
                "Start Time": "وقت البدء",
                'Guide language': 'لغة الدليل',
                "Pick Up Location": "مكان الاستلام",
                "Custom Pick Up Location": "مكان الاستلام المخصص",
                "Drop Off Location": "مكان التوصيل",
                "Custom Drop Off Location": "مكان التوصيل المخصص",
                "manageBaggage": "إدارة الأمتعة",
                "youCanAddGuestsOnlyUpToTheAvailableSeats": "يمكنك إضافة ضيوف فقط حتى المقاعد المتاحة",
                "shoppingCartButtonTooltip": "اختر تكويناً وأضف إلى الطلب",
                "totalPrice": "المبلغ الإجمالي",
                "proceedToPayment": "المتابعة للدفع",
                "placeOrder": "المتابعة للتسجيل",
                "NoHotelWasChosenForTheVacation": "لم يتم اختيار فندق للإجازة",
                "betweenDates": "بين التواريخ",
                "packageInformation": "تفاصيل الحزمة",
                "rooms": "تفاصيل الغرف",
                "transfers": "النقل",
                "reg_transfers": "نقل/مكوك من وإلى الفندق",
                "shuttle_transfers": "مكوك من الحدود إلى الفندق والعودة",
                "flights": "الرحلات",
                "activities": "المعالم",
                "details": "التفاصيل",
                "shoppingCart": "عربة التسوق",
                "package": "ليالي في الفندق",
                "nights": "ليالي",
                "inHotel": "في الفندق",
                "apply": "تأكيد",
                "promoCode": "كود الخصم",
                "yourShoppingCart": "عربة التسوق الخاصة بك",
                "isEmpty": "ما زالت فارغة...",
                "myVacation": "إجازتي",
                "showAll": "عرض الكل »",
                "beforeDiscount": "الإجمالي قبل الخصم -",
                "agentCommission": "عمولة الوكيل -",
                "agentCommissionNet": "عمولة الوكيل (الدفع الصافي) -",
                "discountFromCode": "خصم من الكود -",
                "affiliateWith": "بالتعاون مع -",
                "tpId": "معرف الطلب -",
                "chargeTypeFullCC": "الدفع الكامل - بما في ذلك العمولة",
                "chargeTypeNetCC": "الدفع الصافي للسعر",
                "chargeTypeCash": "معاملة نقدية",
                "agentCommissionPrec": "نسبة عمولة الوكيل",
                "priceOff": "الخصم",
                "percentageOff": "نسبة الخصم",
                "selectedItems": "العناصر المختارة",
                "viewProduct": "عرض المنتج",
                "infantAdditionalPrice": "سعر إضافي للرضيع",
                "netPriceWarning": "السعر الصافي، يرجى إضافة الربح!",
                "copyOrderLink": "نسخ الرابط",
                "createOrder": "حفظ العرض",
                "updateCommission": "حفظ",
                "commissionNotSaved": "الرجاء الضغط للحفظ!",
                "clearCart": "انقر لإفراغ السلة",
                "assignCustomer": "تعيين العميل",
                "firstName": "الاسم الأول",
                "lastName": "الاسم الأخير",
                "phone": "الهاتف",
                "comment": "تعليق",
                "email": "البريد الإلكتروني",
                "wrongEmailFormat": "تنسيق البريد الإلكتروني غير صحيح",
                "saveCustomer": "حفظ العميل",
                "updateCustomerTitle": "تحديث بيانات العميل",
                "createCustomerTitle": "إنشاء عميل جديد",
                "sendByWhatsApp": "إرسال عبر WhatsApp",
                "copyText": "نسخ النص",
                "sendBySMS": "إرسال عبر SMS",
                "findQuoteFor": "إرسال العرض عبر",
                "linkIsGive": "العرض متاح",
                "dates": "التواريخ",
                "hotelName": "اسم الفندق",
                "flightPrice": "سعر الرحلة",
                "suitcase": "حقيبة",
                "trolly": "عربة",
                "passengers": "الركاب",
                "totalAgentComm": "إجمالي عمولة الوكيل",
                "agentOptions": "خيارات الوكيل",
                "compareDisableForAgent": "مقارنة الأسعار متاحة عند وجود عرضين في السلة",
                "createNewUser": "إنشاء عرض جديد",
                "comparePrice": "مقارنة الأسعار",
                "send": "إرسال العرض",
                "profitPercentageChange": "تغيير نسبة الربح",
                "or": "أو",
                "approval": "الموافقة",
                "profitPerPerson": "الربح لكل شخص",
                "grossProfitPercentage": "نسبة الربح الإجمالية",
                "actionNotAllowed": "الإجراء غير مسموح",
                "orderIsAlreadySaved": "الطلب محفوظ بالفعل ولا يمكن إضافة منتجات إضافية",
                "clickToClear": "انقر لإفراغ السلة",
                "roomsByRequestSubjectOfHotelAvailability": "لا يمكن إضافة نفس نوع الغرفة، يرجى اختيار نوع مختلف من الغرف",
                "roomsByRequestSubjectOfHotelAvailabilityTitle": "فشل في إضافة الغرفة",
                "attractionAlreadyAdded": "لا يمكن إضافة نفس نوع المعلم، يرجى اختيار نوع مختلف من المعلم",
                "failedToAddAttraction": "فشل في إضافة المعلم",
                "noAvailabilityOnThisDates": "لا توجد توافر للتواريخ المحددة",
                "attractionSearchFailed": "فشل البحث عن المعالم",
                "cannotSearchForThePastDates": "لا يمكن البحث عن التواريخ الماضية",
                "searchOtherForDates": "لا توجد توافر، يرجى البحث عن تواريخ متاحة أخرى",
                "adult": "بالغ",
                "child": "طفل",
                "infant": "رضيع",
                "perPerson": "لكل شخص",
                "perVehicle": "لكل مركبة",
                "group": "مجموعة",
                "family": "عائلة",
                "perEquipment": "لكل معدة",
                "perBoat": "لكل قارب",
                "none": "لا شيء"
            },
            "checkout": {
                "fill_out_at_least_one_guest_fields": "يرجى ملء جميع الحقول على الأقل لأحد الضيوف!",
                "addRestOfGuests": "Add Rest Of Guests",
                "hideRestOfGuests": "Hide Rest Of Guests",
                "addBaggage": "إضافة أمتعة",
                "newGuest": "ضيف جديد",
                "chooseExistingGuest": "استخدم ضيف موجود",
                "additionalBaggage": "أمتعة إضافية",
                "free": "مجانا",
                "baggageIsForBothFlights": "يمكنك إضافة أمتعة لرحلة الذهاب والعودة",
                "additionalBaggageForThisGuest": "أمتعة إضافية لهذا الضيف",
                "orderFailedToLoad": "حدث خطأ أثناء تحميل العرض",
                "cartNoLongerValid": "السلة لم تعد صالحة",
                "room": "غرفة",
                "personalInfo": "تفاصيل الطلب",
                "paymentDetails": "الدفع",
                "orderCompletion": "إنهاء الطلب",
                "userDetails": "معلومات العميل",
                "name": "الاسم الأول",
                "lastName": "اسم العائلة",
                "passport": "جواز السفر",
                "birthdate": "تاريخ الميلاد",
                "phone": "الهاتف",
                "email": "البريد الإلكتروني",
                "notesForTheOrder": "ملاحظات حول الطلب",
                "namesOfGuests": "المسافرون",
                "namesMustMatchPassport": "* يجب أن تتطابق جميع الأسماء مع ما هو موجود في جواز السفر باللغة الإنجليزية",
                "goToPayments": "إرسال والمتابعة للدفع",
                "saveAndCommit": "حفظ والالتزام بالعرض",
                "saveAndPayLater": "حفظ والدفع لاحقًا",
                "saveOrder": "حفظ الطلب",
                "agreement": "أوافق على شروط الموقع وشروط الطلب وشروط إلغاء الطلب",
                "men": "ذكر",
                "women": "أنثى",
                "pleaseInputYourPhone": "يرجى إدخال رقم هاتفك",
                "pleaseInputYourLastName": "يرجى إدخال اسم عائلتك",
                "pleaseInputYourFirstName": "يرجى إدخال اسمك الأول",
                "pleaseInputYourEmail": "يرجى إدخال بريدك الإلكتروني",
                "pleaseInputYourPassportNumber": "يرجى إدخال رقم جواز سفرك",
                "pleaseInputYourDateOfBirth": "يرجى إدخال تاريخ ميلادك",
                "pleaseSelectGender": "يرجى اختيار الجنس",
                "maxChildAge": "الطفل بين سن 2-",
                "maxInfantAge": "يجب أن يكون الرضع دون سنتين في وقت الإجازة",
                "pleaseAgreeWithT&C": "يرجى الموافقة على شروط الاستخدام ولوائح الموقع",
                "viewTerms": "عرض اللوائح",
                "invalidEmail": "بريد إلكتروني غير صالح",
                "orderCreateSuccess": "تم حفظ طلبك بنجاح، سيتم توجيهك للدفع",
                "orderCreateFree": "تم حفظ طلبك بنجاح!",
                "orderUpdateSuccess": "تم تحديث طلبك بنجاح!",
                "mainRoomGuestTitle": "غرفة باسم",
                "uploadPassportImage": "تحميل صورة جواز السفر",
                "addInfant": "إضافة رضيع",
                "updateInfant": "تحديث عدد الرضع",
                "howManyInfantsDoYouWantToAdd": "اختر عدد الرضع لهذه الغرفة",
                "add": "إضافة",
                "setInfantCountForRoom": "تحديث عدد الرضع",
                "setInfantCountForRoomSuccess": "تم تحديث عدد الرضع بنجاح",
                "infantsAddedToTheRoom": "تم إضافة رضع إلى الغرفة",
                "selectNumberOfInfants": "اختر عدد الرضع",
                "pricePerInfant": "السعر لكل رضيع",
                "infantsRemoved": "تم إزالة الرضع من هذه الغرفة",
                "requiredUploadPassportImage": "تحميل صورة جواز السفر إلزامي",
                "infantError4403": "لا يمكن تغيير عدد الرضع - يرجى الاتصال بمركز الخدمة",
                "docketId": "* رقم الدوكيت",
                "agencyId": "* رقم الوكالة"
            },
            "manageOrder": {
                "questions": "أسئلة",
                "moveToPayment": "إضافة دفعة",
                "backToManage": "العودة لإدارة الطلب",
                "paymentTitle": "دفع الطلب",
                "manageTitle": "تحديث معلومات الطلب",
                "modelNotFound": "الطلب غير موجود",
                "orderId": "إدارة الطلب",

                "room_booking_cancelled_successfully": "تم إلغاء حجز الغرفة بنجاح",
                "room_booking_cancel_error": "حدث خطأ أثناء إلغاء حجز الغرفة",
                "cancel_room_booking_warning": "بإلغاء الغرفة، ستفقد الحجز والسعر. هل أنت متأكد؟",
                "cancel_booking": "إلغاء الحجز"
            },
            "attractionQuestions": {
                "customerInfo": "معلومات العميل",
                "participantsInfo": "معلومات المشاركين",
                "first_name": "الاسم الأول",
                "last_name": "اسم العائلة",
                "email_address": "عنوان البريد الإلكتروني",
                "phone_number": "رقم الهاتف",
                "pickup_location": "موقع الاستلام",
                "height_in_centimeters": "الطول بالسنتيمترات",
                "weight_in_kilograms": "الوزن بالكيلوغرامات"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "تم الطلب بنجاح - تم الدفع",
                    "info": "تم إرسال بريد إلكتروني بتفاصيل الطلب إلى عنوانك"
                },
                "waitingToApprove": {
                    "title": "تم إرسال طلبك للموافقة",
                    "info1": "تم حفظ تفاصيل الطلب في النظام وأرسل للموافقة.",
                    "info2": "سيتم الدفع فقط بعد الموافقة على الطلب.",
                    "info3": "سيتم إرسال بريد إلكتروني بتأكيد الطلب والدفع بعد الموافقة."
                },
                "paymentFail": {
                    "title": "فشل الدفع",
                    "info1": "فشل الدفع :(",
                    "infoFail": "بعد عدة محاولات، فشل الشحن - يرجى المحاولة مرة أخرى",
                    "infoWhatsapp": "إذا حدث خطأ ولا يمكنك الدفع، يرجى الاتصال عبر WhatsApp على:"
                },
                "orderNum": "رقم الطلب",
                "thanksForChoose": "شكرًا لاختيارك",
                "free": {
                    "title": "تم التسجيل بنجاح",
                    "info": "تم إرسال بريد إلكتروني مع ملخص الطلب إليك"
                },
                "pay_later": {
                    "title": "تم التسجيل بنجاح",
                    "info": "تم إرسال بريد إلكتروني بتأكيد الطلب إليك"
                }
            },
            "paymentForm": {
                "priceCanNotBeOverBalance": "السعر لا يمكن أن يتجاوز الرصيد",
                "total": "الرابط الكلي",
                "balance": "الرصيد الكلي",
                "updatedBalance": "الرصيد المُحدث",
                "copyLink": "نسخ الرابط",
                "linkFor": "رابط لـ",
                "back": "عودة",
                "setSpecificPrice": "رابط الدفع",
                "createLink": "إنشاء رابط",
                "setPriceAndSendPaymentLinkToClient": "تحديد السعر وإرسال رابط الدفع للعميل",
                "clientName": "اسم العميل",
                "paymentPageFor": "صفحة الدفع لـ",
                "forPayment": "للدفع",
                "price": "السعر",
                "copyPaymentLink": "نسخ رابط الدفع",
                "pleaseFillInBothFields": "يرجى ملء كلا الحقلين.",
                "paymentLinkCopiedToClipboard": "تم نسخ رابط الدفع إلى الحافظة!",
                "createLinkForPrice": "إنشاء رابط للدفع",
                "creditNotSaveInBd": "تفاصيل بطاقة الائتمان لا تُحفظ على الموقع - أنت تقوم بعملية دفع آمنة",
                "title": "أدخل طريقة الدفع (فيزا وماستركارد فقط)",
                "splitBtn": "اضغط لتقسيم بطاقات الائتمان",
                "splitTitle": "تقسيم بطاقات الائتمان",
                "splitDesc": "اختر عدد بطاقات الائتمان للتقسيم واضغط تأكيد",
                "noSplit": "بدون تقسيم",
                "noPayments": "بدون دفعات",
                "payments": "الدفعات",
                "paymentsNum": "عدد الدفعات",
                "withDebit": "باستخدام الائتمان",
                "splitBy": "بطاقات الائتمان",
                "toPay": "للدفع",
                "splitApprove": "تأكيد",
                "currencyRate": "التحصيل بالشواكل - حسب سعر السياحة",
                "splitCurrentCardNum": "تقسيم الائتمان",
                "splitCurrentCardNumFrom": "من أصل",
                "errorMessageToRetry": "محاولة رقم {retryNum} - فشل الدفع من شركة بطاقة الائتمان. يرجى إدخال تفاصيل بطاقة أخرى. الموقع يقبل بطاقات فيزا وماستركارد فقط."
            },
            "hotelTypes": {
                "Hotel": "فندق"
            },
            "hotelDetails": {
                "updateDates": "تحديث التواريخ",
                "roomTypesToChooseFrom": "أنواع الغرف للاختيار من بينها",
                "metapolicy": "سياسة الفندق",
                "metaExtraInfo": "معلومات إضافية",
                "not_included": "غير مشمول",
                "included": "مشمول",
                "price": "بتكلفة",
                "address": "العنوان",
                "Location": "موقع الفندق",
                "At the boutique hotel": "حول الفندق",
                "Room amenities": "مرافق الغرفة",
                "parking": "موقف سيارات",
                "pets": "الحيوانات الأليفة",
                "shuttle": "النقل إلى المطار (ذهاب فقط)",
                "internet": "خدمات الإنترنت",
                "extra_bed": "سرير إضافي",
                "add_fee": "رسوم إضافية",
                "children_meal": "وجبة الأطفال",
                "check_in_check_out": "تسجيل الوصول والمغادرة"
            },
            "sideFilters": {
                "sortBy": "فرز بحسب",
                "recommended": "موصى به",
                "price": "السعر",
                "clearSideFilters": "مسح الفلاتر",
                "ts_type": "أنواع الرحلات",
                "auto_approve": "الموافقة الفورية",
                "include_transfer": "تشمل النقل",
                "include_baggage": "تشمل الأمتعة",
                "include_trolly": "تشمل عربة",
                "auto_approve_checked": "عرض نتائج الموافقة الفورية",
                "from_date_hours": "وقت الرحلة الصادرة",
                "return_date_hours": "وقت الرحلة العائدة",
                "airline": "شركة الطيران",
                "2": "رحلات مستأجرة",
                "3": "رحلات مجدولة",
                "nonStop": "رحلة مباشرة",
                "stop": "توقف واحد",
                "stops_plural": "توقفات",
                "hotel_type": "أنواع الإقامة",
                "stops": "عدد التوقفات",
                "freeCancellation": "إلغاء مجاني؟",
                "freeCancellationLabels": {
                    "1": "إلغاء مجاني فقط",
                    "0": "بدون إلغاء مجاني"
                },
                "feature": "ميزة"
            },
            "package_feature": {
                "SPECIAL PACKAGE": "حزمة خاصة",
                "ALL PACKAGES": "جميع الحزم",
                "KOSHER": "كوشر",
                "SKI": "تزلج",
                "EVENT": "فعالية",
                "SPORT": "رياضة",
                "SPA": "سبا",
                "YOUNG VACATION": "إجازة للشباب",
                "FLT+HTL+CAR": "FLT+HTL+CAR"
            },
            "general": {
                "close": "إغلاق",
                "attraction": "معلم",
                "includeReturnSuitcase": "شمل حقيبة للرحلة العودة",
                "total": "المجموع",
                "as_of_today": "حتى اليوم",
                "link_to_the_offer": "رابط العرض",
                "the_quote_has_been_copied_successfully": "تم نسخ العرض بنجاح",
                "base": "أساس",
                "sort": "ترتيب",
                "submit": "إرسال",
                "maxAmountOf": "الحد الأقصى للمبلغ من",
                "minAmountOf": "الحد الأدنى للمبلغ من",
                "ticketType": "نوع التذكرة",
                "cancellation_policy": "سياسة الإلغاء",
                "additionalInfo": "معلومات إضافية",
                "hour": "ساعة",
                "minute": "دقيقة",
                "before": "قبل",
                "date": "التاريخ",
                "duration": "المدة",
                "quantity": "الكمية",
                "for": "لـ",
                "free": "مجاناً",
                "freeOfCharge": "مجاناً",
                "option": "خيار",
                "removeSelection": "إلغاء الاختيار",
                "grandTotalPrice": "المجموع الكلي",
                "startingPrice": "سعر البداية",
                "usd": "دولار أمريكي",
                "euro": "يورو",
                "address": "العنوان",
                "type": "النوع",
                "noResults": "لا نتائج",
                "boardBasis": "أساس الإقامة",
                "nights": "ليالي",
                "supplier": "المورد",
                "airLine": "شركة الطيران",
                "price": "السعر",
                "totalRoomPrice": "السعر الكلي للغرفة",
                "priceForXRooms": "السعر لـ X غرف",
                "hotel": "فندق",
                "hotel_stars": "نجوم الفندق",
                "minPrice": "السعر الأدنى",
                "BB": "إفطار وسرير",
                "HB": "نصف إقامة",
                "cancel": "إلغاء",
                "replaceShoppingCart": "استبدال سلة التسوق",
                "replace": "استبدال",
                "searchForUser": "البحث عن مستخدم",
                "acceptCookiesTitle": "يستخدم هذا الموقع ملفات تعريف الارتباط لضمان حصولك على أفضل تجربة على موقعنا.",
                "acceptCookiesBtn": "قبول",
                "includesActivities": "{NUMBER} من الأنشطة كهدية!",
                "includesActivity": "يشمل نشاط كهدية!",
                "adults": "بالغين",
                "all_include": "كل شيء مشمول",
                "breakfast": "إفطار",
                "half_board": "نصف إقامة",
                "half_board_kosher": "نصف إقامة كوشر",
                "full_board": "إقامة كاملة",
                "sleep_only": "إقامة فقط",
                "breakfast_kidush": "إفطار وعشاء الجمعة",
                "addToCard": "إضافة إلى الطلب",
                "chooseRoom": "اختيار التركيبة والغرفة",
                "boardType": "أساس الإقامة",
                "filters": "مرشحات",
                "rating": "تقييم الفندق",
                "attraction_stars": "تقييم المعلم",
                "selected": "محدد",
                "name": "الاسم",
                "taxonomies": "التصنيفات",
                "children": "أطفال",
                "toDate": "حتى",
                "transfers": "النقل",
                "from": "من",
                "perPerson": "لكل شخص",
                "perPax": "لكل فرد",
                "include": "يشمل",
                "noIncludeText": "حزم العطلات",
                "flights": "رحلات جوية",
                "vacationAt": "عطلة في {DEST}",
                "fieldRequired": "حقل إلزامي",
                "showAll": "عرض الكل",
                "showLess": "عرض أقل",
                "flight": "رحلة",
                "h": "س",
                "min": "دق",
                "availableSeats": "المقاعد المتوفرة",
                "showGrossPrice": "عرض السعر الإجمالي",
                "showNetPrice": "عرض السعر الصافي",
                "noRating": "بدون تقييم",
                "bundleFlight": "رحلة ذهاب وعودة",
                "trollyIsIncluded": "تشمل عربة",
                "trollyIsNotIncluded": "بدون عربة",
                "suitcaseIsIncluded": "تشمل حقيبة",
                "suitcaseIsNotIncluded": "بدون حقيبة",
                "additionalSuitcases": "يشمل X حقائب",
                "additionalTrolly": "يشمل X عربات",
                "returnFlight": "رحلة العودة",
                "outboundFlight": "رحلة الذهاب",
                "agentMode": "وضع الوكيل",
                "userMode": "وضع العميل",
                "suitcase": "حقيبة",
                "trolly": "عربة",
                "packageSearchResult": "الحزم التي وجدناها لك",
                "day": "يوم",
                "selectDate": "اختر التواريخ",
                "search": "بحث",
                "adult": "بالغ",
                "child": "طفل",
                "infant": "رضيع",
                "infants": "رضع",

                "loading": "جارٍ التحميل",
                "showingMoreResults": "عرض المزيد من النتائج",
                "noMoreResults": "لا توجد نتائج أخرى",
                "NoResultsMatchedTheFilterValues": "لم تتطابق أي نتائج مع قيم الفلتر"
            },
            "subsidizedForm": {
                "title": "إضافة دعم",
                "employee_id": "رقم الموظف",
                "person_id": "رقم الهوية (9 أرقام)",
                "validate": "تحقق من كود الموظف",
                "alreadyApplied": "الدعم مطبق بالفعل",
                "addOneMore": "إضافة دعم آخر",
                "addOneMoreId": "إضافة موظف آخر",
                "fieldRequired": "حقل إلزامي",
                "priceReducedBy": "تم تخفيض السعر بمقدار",
                "forEmployee": "للموظف",
                "employeeIs": "تم التعرف بنجاح",
                "error4401": "فشل التعرف، حاول مجدداً",
                "error4402": "تم التعرف على الكود، لكنه مستخدم بالفعل",
                "guestIdentifiedId": "تم التعرف بواسطة الهوية",
                "guestNotIdentified": "يجب التعرف قبل التسجيل",
                "clickToIdentify": "انقر للتعرف"
            },

            "uploadImage": {
                "uploadPassportImage": "تحميل صورة جواز السفر",
                "somethingWentWrong": "حدث خطأ ما"
            },
            "flightButton": {
                "no_availability_for_this_flight_price": "عذرًا، لا توجد مقاعد متوفرة لهذه الأسعار",
                "cardConditions": "شروط البطاقة",
                "flightTotalPrice": "سعر الرحلة لاتجاه واحد",
                "total": "المجموع",
                "continueToOrder": "الانتقال إلى الطلب",
                "addToCart": "إضافة إلى السلة",
                "lastPlace": "آخر المقاعد المتوفرة",
                "pricePerPassenger": "السعر لكل راكب"
            },
            "agentCommission": {
                "supplier": "المورد",
                "commission": "العمولة",
                "recommendedFee": "الرسوم الموصى بها",
                "transactionDetailsForAgent": "تفاصيل العملية للوكيل",
                "supplierNotes": "ملاحظات المورد",
                "cancellationConditions": "شروط الإلغاء",
                "addToCartToChangeFree": "أضف إلى السلة لتغيير العمولة",
                "pricesShownAreNetPrices": "الأسعار المعروضة هي أسعار صافية",
                "pricesShownAreGrossPrices": "الأسعار المعروضة هي أسعار إجمالية",
                "packageRemarks": "ملاحظات الحزمة"
            },
        }
    }
};

export default translationsAr;