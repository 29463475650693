export enum OrderStatus {
  NEW = 'new',
  WAITING_TO_APPROVE = 'waiting_to_approve',
  APPROVED = 'approved',
  IN_PAYING = 'in_paying',
  PAYMENT_FINISH = 'payment_finish',
  PAYMENT_FAILED = 'payment_failed',
  PROPOSAL = 'proposal',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  WAITING_TO_APPROVE_WITH_TOKEN = 'waiting_to_approve_with_token',
  TOKEN_FAILED = 'token_failed',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  CANCELLED_BY_SUPPLIER = 'cancelled_by_supplier',
  CANCELLED_NOT_APPROVE = 'cancelled_not_approve',
  DUPLICATED = 'duplicated',
  NOT_APPROVED = 'not_approved',
  VALIDATED = 'validated',
}

export enum ProductType {
  PACKAGE = 'package',
  ROOM = 'room',
  ACTIVITY = 'activity',
  TRANSFER = 'transfer',
  FLIGHT = 'flight',
  SUITCASE = 'suitcase',
  TROLLY = 'trolly',
  HOTEL = 'hotel',
  FLIGHT_PACKAGE = 'flight_package',
  ALL = 'all',
  ONE_WAY_FLIGHTS = 'one_way_flights',
  ONE_WAY_FLIGHT = 'one_way_flight',
  ATTRACTION = "attraction",
  ATTRACTION_TICKET = "attraction_ticket",
  Area = 'area',
}

export enum ModelType {
  hotel = 1,
  flight = 20,
  package = 3,
  room = 2,
  userAgent = 11,
  tenantTs = 28,
  suitecase = 100,
  trolly = 101,
  flight_package = 103,
  one_way_flights = 104,
  one_way_flight = 105,
  room_infant = 106,
  attraction = 4,
  attraction_ticket = 107,
  area = 10,
}

export enum OrderProductStatus {
  NEW = 'new',
  VALIDATION_FAILED = 'validation_failed', // IF TS failed to validate the product and price
  VALIDATED = 'validated', // AFTER TS Success to validate the product and price
  WAITING_TO_APPROVE = 'waiting_to_approve', // when order paid - we can start process of create docket until it approved
  APPROVED = 'approved', // TS Vendor approved the order
  NOT_APPROVED = 'not_approved', // TS Vendor not approved the order
  CANCELLED = 'cancelled',
  DELETED = 'deleted',
  ONGOING_CANCELLATION = 'ongoing_cancellation', // NOT Exist in DB (YET)
  NOT_AVAILABLE = 'not_available',
  MISSING_DATA = 'missing_data',
  CANCELLED_BY_SUPPLIER = 'cancelled_by_supplier',
  APPROVED_AUTO_CANCELLATION = 'approved_auto_cancellation',
}
