import { useDispatch, useSelector } from 'react-redux';
import PersonSVG from '../../../RoomPricing/assets/PersonSVG';
import {
  Product,
  handleRemoveRoom,
  handleAdjustGuestIndexes,
} from '../../ShoppingCartSliceV2';
import React from 'react';
import { RootState } from '../../../../store/store';
import { IoClose } from 'react-icons/io5';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from 'antd';
import CancellationTooltip from '@skygroup/shared/components/HotelRoomTooltips/CancellationTooltip/CancellationTooltip';
import PriceTooltip from '@skygroup/shared/components/HotelRoomTooltips/PriceTooltip/PriceTooltip';
import ShoppingCartAdditionalItem from '../ShoppingCartAdditionalItem/ShoppingCartAdditionalItem';
import moment from 'moment';
import { OrderProductStatus } from '@skygroup/shared/constants/OrderProductConsts';
import { HiBadgeCheck } from 'react-icons/hi';
import { TbCircleX } from 'react-icons/tb';
import { RiLoader2Fill } from 'react-icons/ri';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';

const getStatusIcon = (
  status: OrderProductStatus,
  shoppingCartTranslations: any
) => {
  switch (status) {
    case OrderProductStatus.APPROVED:
      case OrderProductStatus.APPROVED_AUTO_CANCELLATION:
      return (
        <Tooltip title={shoppingCartTranslations.productIsApproved}>
          <HiBadgeCheck size={16} color="#4dd55d" />
        </Tooltip>
      );
    case OrderProductStatus.CANCELLED:
      return (
        <Tooltip title={shoppingCartTranslations.productIsCancelled}>
          <TbCircleX size={16} color="red" />
        </Tooltip>
      );
    case OrderProductStatus.NOT_APPROVED:
      return (
        <Tooltip title={shoppingCartTranslations.productIsNotApproved}>
          <TbCircleX size={16} color="red" />
        </Tooltip>
      );
    case OrderProductStatus.VALIDATED:
    case OrderProductStatus.WAITING_TO_APPROVE:
      return (
        <>
          <RiLoader2Fill size={16} className="rotate" />
          <Tooltip title={shoppingCartTranslations.waitingToApprove}>
            <AiOutlineQuestionCircle
              size={16}
              className={'hint additional-price-info'}
            />
          </Tooltip>
        </>
      );
    default:
      return null;
  }
};

interface Props {
  product: Product;
  freePackage: any;
  isCheckoutPage: boolean;
  identifier?: string;
  isSameDates?: boolean;
  showProductPrice?: boolean;
}

const ShoppingCartRoom = ({
  product,
  freePackage,
  isCheckoutPage,
  identifier,
  isSameDates,
  showProductPrice = true,
}: Props) => {
  const {
    shoppingCartTranslations: t,
    roomPricing: t_roomPricing,
    boards: t_boards,
  } = useSelector((data: RootState) => data.config.translations);
  // This is relevant only when we are in package page ?
  // Why it's needed?
  const { packageData } = useSelector((state: RootState) => state.package);
  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const adultsNum =
    product.product_data?.price?.adult ?? product.product_data.adult;
  const childNum =
    product.product_data?.price?.child ?? product.product_data.child ?? 0;
  const infantNum = product.subProducts?.[0]?.related_guest_indexes.length ?? 0;
  const board =
    product.product_data?.price?.board ?? product.product_data.board ?? '';

  const dispatch = useDispatch();

  const getRoomDetail = (adultsNum: number, childNum: number) => {
    let roomDetail = `${t_boards?.[board] ?? board}`;
    if (adultsNum == 1) {
      roomDetail += ` | ${t_roomPricing?.single}`;
    } else {
      roomDetail += ` | ${adultsNum} ${t_roomPricing?.adults}`;
    }

    if (childNum > 0) {
      if (childNum == 1) {
        roomDetail += ` + ${t_roomPricing?.child}`;
      } else {
        roomDetail += ` + ${childNum} ${t_roomPricing?.children}`;
      }
    }

    if (infantNum > 0) {
      if (infantNum == 1) {
        roomDetail += ` + ${t_roomPricing?.infant}`;
      } else {
        roomDetail += ` + ${infantNum} ${t_roomPricing?.infants}`;
      }
    }

    return roomDetail;
  };

  const taxes =
    product?.product_data?.pricingInfo?.additional_info?.payment_options
      ?.payment_types?.[0]?.tax_data?.taxes;

  const isShowAdditionalInfoToolTip =
    product?.product_data?.pricingInfo || taxes;

  const multiRoom = product.product_data?.multi_room_guests ?? [];

  const { siteConfig } = useSelector((data: RootState) => data.config.config);

  return (
    <div className="hotel-room-product sub-product rooms-activities">
      <div className="product-line">
        <div className="activity">
          <div className="occupiers">
            <div className="adult">
              <PersonSVG />
              <span>{`X${adultsNum}`}</span>
            </div>
            {childNum > 0 && (
              <div className="child">
                <PersonSVG />
                <span>{`X${childNum}`}</span>
              </div>
            )}
          </div>

          <span>
            <div className="name">
              {product.name}
              {product?.product_status && (
                <div className="product-status">
                  {getStatusIcon(product?.product_status, t)}
                </div>
              )}
            </div>
            <div className="small-info-group">
              <div className="board">{getRoomDetail(adultsNum, childNum)}</div>
              {isShowAdditionalInfoToolTip && (
                <Tooltip
                  title={
                    <>
                      {product?.product_data?.pricingInfo
                        ?.free_cancelation_date !== undefined && (
                        <CancellationTooltip
                          cancellation_penalties={
                            product?.product_data?.pricingInfo?.additional_info
                              ?.payment_options?.payment_types?.[0]
                              ?.cancellation_penalties
                          }
                          currency_code={
                            product?.product_data?.pricingInfo?.additional_info
                              ?.payment_options?.payment_types?.[0]
                              ?.currency_code
                          }
                          roomPricingT={t_roomPricing}
                        />
                      )}
                      {taxes?.length > 0 && (
                        <PriceTooltip
                          taxes={taxes}
                          roomPricingT={t_roomPricing}
                        />
                      )}
                    </>
                  }
                >
                  <AiOutlineQuestionCircle
                    size={18}
                    className={'hint additional-price-info'}
                  />
                </Tooltip>
              )}
            </div>
            <div className="title">
              {!isSameDates &&
                `${moment((product.date_start as any) * 1000)
                  .utc()
                  .format(siteConfig.dateFormat)}
              -
              ${moment((product.date_end as any) * 1000)
                .utc()
                .format(siteConfig.dateFormat)}`}
            </div>
          </span>
        </div>
        {/* // TODO TP_ID needs to be here */}
        {false ? null : (
          <div className="price-delete">
            {showProductPrice && !freePackage && (
              <b>
                {(product.total_price
                  ? product.total_price
                  : product.product_data.price.room_price
                ).toFixed()}
                {String.fromCharCode(160)}
                {ShoppingCartV2.currency_sign}
              </b>
            )}
            {!isCheckoutPage && (
              <IoClose
                size={15}
                onClick={() => {
                  dispatch(
                    handleRemoveRoom({
                      ...product,
                      packageId: packageData.id,
                      roomId:
                        product?.product_data?.pricingInfo?.room_id ??
                        product.product_data.price.id,
                      identifier: identifier,
                    })
                  );
                  dispatch(handleAdjustGuestIndexes(product));
                }}
                className="delete-product ease"
              />
            )}
          </div>
        )}
      </div>

      <div
        className="product-line"
        style={{ flexDirection: 'column', alignItems: 'start' }}
      >
        {multiRoom.map((room: any, index: number) => {
          return (
            <div className="small-info-group" key={index}>
              <div className="activity">
                <div className="occupiers">
                  <div className="adult">
                    <PersonSVG />
                    <span>{`X${room.adult}`}</span>
                  </div>
                  {room.child > 0 && (
                    <div className="child">
                      <PersonSVG />
                      <span>{`X${room.child}`}</span>
                    </div>
                  )}
                </div>
                <span>
                  <div className="name">{product.name}</div>
                  <div className="small-info-group">
                    <div className="board">
                      {getRoomDetail(room.adult, room.child)}
                    </div>
                  </div>
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <ShoppingCartAdditionalItem
        subProducts={product.subProducts}
        text={t.infantAdditionalPrice}
        showProductPrice={showProductPrice}
      />
    </div>
  );
};

export default ShoppingCartRoom;
